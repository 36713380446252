import { loadPrebid } from '@arkadium/jarvis';

import { JarvisResponse } from './config-service';
import { GeoService } from "./geo";

const TIMEOUT = 2500;
const GDPRCountryCodes = [ 'AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE', 'GB', 'GF', 'GP', 'MQ', 'ME', 'YT', 'RE', 'MF', 'GI', 'AX', 'PM', 'GL', 'BL', 'SX', 'AW', 'CW', 'WF', 'PF', 'NC', 'TF', 'AI', 'BM', 'IO', 'VG', 'KY', 'FK', 'MS', 'PN', 'SH', 'GS', 'TC', 'AD', 'LI', 'MC', 'SM', 'VA', 'JE', 'GG', 'GI', 'CH', ];

function getEids() {
  let id = (document.cookie.match(/ai_user=(.+?);/) || [])[1];
  if (!id) {
    try {
      id = localStorage.getItem('adsid') || '';
      if (!id) {
        id = btoa(Math.random().toString());
        localStorage.setItem('adsid', id);
      }
    } catch (e) {
      id = 'anon';
    }
  }
  return { source: 'arkadium.com', uids: [{ atype: 1, ext: { stype: 'ppuid' }, id }] };
}

const defaultUserIds = [
  { name: 'lotamePanoramaId' },
  { name: 'sharedId', storage: { type: 'cookie', name: '_pubcid', expires: 365 } },
  { name: 'uid2' },
  { name: 'idx' },
  { name: 'criteo' },
  { name: 'pubProvidedId', params: { eidsFunction: getEids } },
];

export class PrebidService {
  protected pb: any;
  protected bids: any[];
  protected userIds: any[];
  protected prebidLoaded: Promise<any>;
  protected prebidConfigured: Promise<any>;
  protected schain: any;

  constructor(config: JarvisResponse, protected dfpNetworkCode: string, protected inventoryCode: string) {
    this.bids = config.config.bids;
    const jarvisUserIds = config.config.userIds.map((u) => {
      if (['criteo', 'lotamePanoramaId'].includes(u.name)) return { name: u.name };
      return u;
    });

    this.userIds = jarvisUserIds.length > 0 ? jarvisUserIds : defaultUserIds;

    if (config.schain) {
      this.schain = {
        validation: 'relaxed',
        config: {
          ver: '1.0',
          complete: 1,
          nodes: [{ asi: 'arkadium.com', sid: config.schain, hp: 1 }],
        },
      };
    }

    this.prebidLoaded = this.prebidConfigured = loadPrebid(config);
  }

  public async addAdUnit(adUnit: any) {
    const slotName = `/${this.dfpNetworkCode}/${this.inventoryCode}/${adUnit.id}`;
    const prebid = await this.prebidConfigured;

    prebid.addAdUnits({
      code: adUnit.id,
      bids: this.bids.map(({ bidder, params }) => ({ bidder, params })),
      sizes: adUnit.size,
      mediaTypes: {
        banner: { sizes: adUnit.size },
      },
      ortb2Imp: {
        ext: {
          data: {
            adserver: {
              adslot: slotName,
              name: "gam"
            },
            pbadslot: slotName
          },
          gpid: slotName
        },
      },
    });
  }

  public async removeAdUnit(adUnit: any) {
    const prebid = await this.prebidConfigured;
    prebid.removeAdUnit(adUnit.id);
  }

  public requestBids(adUnitCodes: any[]) {
    return new Promise(async (resolve, reject) => {
      const timeout = setTimeout(resolve, TIMEOUT);
      const prebid = await this.prebidConfigured;
      prebid.requestBids({
        adUnitCodes,
        timeout: TIMEOUT,
        bidsBackHandler: (bids: any[]) => {
          clearTimeout(timeout);
          window.googletag.cmd.push(() => {
            prebid.setTargetingForGPTAsync();
            resolve(bids);
          });
        }
      });
    });
  }
}
